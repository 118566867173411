import React from 'react';
import '../App.css';
import { useInView } from 'react-intersection-observer';

export default function About() {
  const { ref: aboutRef, inView: inAbout } = useInView();
  const {ref:abouttext,inView:inabouttext} = useInView();

  return (
    <div id="About" className="flex flex-col items-center justify-center px-5 py-44 sm:px-8 sm:py-10 lg:px-16 lg:py-20 h-screen">
      <div
        ref={aboutRef}
        className={`text-white text-center mt-8 sm:mt-12 md:mt-16 lg:mt-20 text-9xl font-custom font-medium tracking-wide ${inAbout ? 'animate-fadeInRight' : ''}`}
      >
        About Us
      </div>

      <div ref={abouttext} className={`flex items-center justify-center mt-6 ${inabouttext?'animate-fadeInLeft':''} sm:mt-8 w-full`}>
        <p className="text-white font-century bg-gray-500 bg-opacity-50 text-base sm:text-lg md:text-xl lg:text-2xl p-4 sm:p-6 md:p-8 lg:p-10 rounded-xl tracking-wider sm:tracking-wider w-full max-w-2xl">
          ZetSpace is dedicated to delivering cutting-edge products that address
          real-time business demands. We specialize in providing reliable
          software solutions across diverse domains, empowering companies with
          scalable and efficient tools.
        </p>
      </div>
    </div>
  );
}
