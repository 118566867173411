import React from 'react'
import Media from './Media'
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { Link } from 'react-scroll';
import { useInView } from 'react-intersection-observer';




export default function Services() {
  const {ref:service,inView:inservice} = useInView();
  return (
    <div id="Services" className='h-screen' >
<div  className={`text-white flex flex-col justify-center items-center h-screen font-custom`}>
<h1 ref={service} className={`font-bold text-7xl md:text-9xl  md:text-11xl tracking-wide w-40 h-36 flex items-center justify-center text-center ${inservice ? 'animate-fadeInUp' : ''}`}>Services</h1>
    <Link to="Dev" spy={true} smooth={true} offset={20} duration={1000} ><IoArrowForwardCircleOutline className='text-7xl md:text-9xl ml-3' /></Link>
        
</div>
</div>
  )
}
