import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import Software from './Software';
import Web from './Web';
import Graphic from './Graphic';
import Video from './Video';
import Ui from './Ui';

export default function CarouselDesign() {
  return (
    <div id="Des1">
       
    <Swiper
         modules={[Navigation, Scrollbar, A11y, EffectCube]}
         spaceBetween={50}
         slidesPerView={1}
         effect="cube"
         speed={1000} 
         navigation
         scrollbar={{ draggable: true }} 
         
    >
    <SwiperSlide className=''>   
        <Ui/>
    </SwiperSlide>

    <SwiperSlide className=''> 
        <Graphic/>   
            
        </SwiperSlide>
        <SwiperSlide className=''> 
        <Video/>  
            
        </SwiperSlide>
    
        </Swiper>
    
    
    </div>
  )
}
