import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import contact from '../assets/images/Vector.png'

export default function Follow() {
  const {ref:follow,inView:infollow} = useInView();
  const {ref:logo,inView:inlogo} = useInView();
  return (
    <div id="Contact" className='h-screen'>
    <div className='flex flex-col items-center justify-center space-y-5 h-[90vh]'>
    <h1 ref={follow} className={`text-white text-7xl md:text-8xl  font-bold font-custom text-center ${infollow?'animate-fadeInLeft':''}`}>Follow  Us</h1>
    <div ref={logo} className={`flex ${inlogo?'animate-fadeInRight':''}`}>
    <a href='https://www.instagram.com/zetspace.in/' target='_blank' rel='noopener noreferrer'>
      <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: '#FFFFFF', margin: '20px' }} />
      </a>
      <a href='https://www.facebook.com/' target='_blank' rel='noopener noreferrer'>

      <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#FFFFFF', margin: '20px' }} />
      </a>
      <a href='https://www.linkedin.com/company/zetspace.in/' target='_blank' rel='noopener noreferrer'>

      <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ color: '#FFFFFF', margin: '20px' }} />
      </a>
      <a href='mailto:zetspaceinfo@gmail.com'>
      <FontAwesomeIcon icon={faEnvelope} size="2x" style={{ color: '#FFFFFF', margin: '20px' }} />
      </a>
      <a href='https://www.zetspace.in/support' target='_blank' rel='noopener noreferrer'>

      <FontAwesomeIcon icon={faQuestionCircle} size="2x" style={{ color: '#FFFFFF', margin: '20px' }} />
      </a>
      <a href='tel:+919361238436'>
      <img src={contact}  className='w-8 h-8 mt-5 ml-3' />
      </a>
    </div>
    </div>
    <div>
  {/* Visible on medium screens and above */}


  {/* Visible on small screens and below */}
  <div className='flex flex-col justify-center items-center text-white md:hidden m-5'>
    <p className='text-start'>E-mail: zetspaceinfo@gmail.com</p>
    <p className='w-1/2 mt-2 text-center'>Address: Sarathy Nagar, Kundrathur, Chennai, Malayambakkam, Tamil Nadu 600069</p>
    <p className='mt-2 text-end'>Contact : +91 93612 38436</p>
  </div>
</div>

    </div>
  )
}
