import React from 'react';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import Media from './Media';
import { Link } from 'react-scroll';
import { useInView } from 'react-intersection-observer';

export default function Welcome() {
  const { ref: welcomeRef, inView: welcomeInView } = useInView(); // Removed triggerOnce: true

  return (
    <div id="Home" className='2xl:container'>
      <Media />
      <div className='text-white flex flex-col justify-center  items-center h-full font-custom' >
        <h1 ref={welcomeRef}  className={`font-bold text-7xl md:text-9xl md:text-11xl tracking-wide w-40 h-36 flex items-center justify-center text-center ${welcomeInView ? 'animate-fadeInUp' : ''}`}>
          WELCOME
        </h1>
        <Link to="page1" spy={true} smooth={true} offset={10} duration={1000}>
          <IoArrowForwardCircleOutline className='text-7xl md:text-9xl' />
        </Link>
      </div>
    </div>
  );
}
