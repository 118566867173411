import React from 'react'
import Media from './Media'
import { Link } from 'react-scroll'

export default function Development() {
  return (
    <div id="Dev" > 
    <div className='flex flex-col justify-center items-center text-white space-y-10 md:space-y-20 py-6 md:py-20 drop-shadow-2xl h-screen'>
        <h1 className='text-white font-bold font-custom text-8xl md:text-9xl'>1</h1>
        <h1 className='md:text-8xl text-7xl font-extrabold font-custom'>Development</h1>
        <Link to="Dev1" spy={true} smooth={true} offset={20} duration={1000}><button className='text-gray-500 bg-white px-4 md:py-5 py-3 rounded-full w-56 '>
        Explore More
       </button></Link>
    </div>
    </div>
  )
}
