import React from 'react';
import '../App.css';
import bg from '../assets/videos/Aura.mp4'

const BGvideo = () => {
  return (
    <div className="video-background">
      <video autoPlay loop muted>
        <source src={bg} type="video/mp4" />
      </video>
    </div>
  );
};

export default BGvideo;
