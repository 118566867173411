import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  name: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    register: (state, action) => {
      state.isLoggedIn = true;
      state.name = action.payload;
    },
    login: (state, action) => {
        const { name, email } = action.payload;
        state.isLoggedIn = true;
        state.name = name;
        state.email = email;
      },
    logout: (state) => {
      state.isLoggedIn = false;
      state.name = '';
    },
  },
});

export const { register,login, logout } = userSlice.actions;
export default userSlice.reducer;

