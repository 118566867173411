import React from 'react';
import Vide from '../assets/videos/Custom_Software_Development.mp4';

export default function Software() {
  return (
    <div className='w-full   flex flex-col space-y-20 md:py-20 py-[35%] items-center h-screen'>
    <h1 className='text-white text-6xl font-bold font-custom'>CUSTOM SOFTWARE DEVELOPMENT</h1>
    <video
        className='rounded-[10px] md:m-0   md:rounded-[12%] w-[70%] md:h-[60vh] md:w-[100vh] h-fit'
        src={Vide}
        muted
        autoPlay
        loop>
      </video>
</div>
  );
}
