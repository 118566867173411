import React from 'react'
import { Link } from 'react-scroll'

export default function Socialmedia() {
  return (
    
    <div className='flex flex-col items-center justify-center text-white space-y-10 md:space-y-20 py-6 md:py-20 drop-shadow-2xl h-screen' id="Services">
        <h1 className='text-white font-bold font-custom text-8xl md:text-9xl'>3</h1>
        <h1 className='md:text-8xl text-7xl font-extrabold font-custom'>Social Media</h1>
        <Link to="Med1" spy={true} smooth={true} offset={10} duration={1000}>
        <button className='text-gray-500 bg-white px-4 md:py-4 py-3 rounded-full w-56 '>
        Explore More
       </button>
       </Link>
    </div>
  )
}
