import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import Social from './Social';
import Multimedia from './Multimedia';
import Media from './Media';

export default function CarouselMedia() {
  return (
    <div id="Med1">
      
    <Swiper
         modules={[Navigation, Scrollbar, A11y, EffectCube]}
         spaceBetween={50}
         slidesPerView={1}
         effect="cube"
         speed={1000} 
         navigation
         scrollbar={{ draggable: true }}
    >
    <SwiperSlide className=''>   
    <Social />
    </SwiperSlide>

    <SwiperSlide className=''> 
    <Multimedia/>
        </SwiperSlide>
       
   
        </Swiper>
    
    
    </div>
  )
}
