import React from 'react'
import Vide from '../assets/videos/UI_UX_Designing.mp4'

export default function Ui() {
  return (
  
    <div className='w-full   flex flex-col space-y-20 md:py-20 py-[55%] items-center h-screen'>
        <h1 className='text-white text-6xl font-bold font-custom'>UI/UX DESIGN</h1>
        <video
            className='rounded-[10px] md:m-0   md:rounded-[12%] w-[70%] md:h-[60vh] md:w-[100vh] h-fit'
            src={Vide}
            muted
            autoPlay
            loop>
          </video>
    </div>

  )
}
