import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { login } from '../redux/userSlice'; // Import the login action

export default function Login({ onClose }) {
  const dispatch = useDispatch();  // Access Redux's dispatch

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://zetspace-backend-1.onrender.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        setMessage('Login successful!');

        // Store data in localStorage and Redux store
        localStorage.setItem('token', data.token);

        // Dispatch login success to store user data in Redux
        dispatch(login({ name: data.name, email: data.email }));

        // Close the login modal
        onClose();
      } else {
        const errorData = await response.json();
        setMessage(`Error: ${errorData.message}`);
      }
    } catch (error) {
      setMessage('Error: Unable to login');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50" onClick={handleOverlayClick}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl relative" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl sm:text-3xl font-bold font-century mb-5">
          Login
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="block text-start text-sm sm:text-base text-gray-700 font-serif font-bold">
              Email
            </label>
            <input
              type="text"
              id="username"
              name="email"
              className="border border-gray-300 p-2 rounded-md w-full"
              placeholder="Enter your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-start text-sm sm:text-base text-gray-700 font-serif font-bold">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="border border-gray-300 p-2 rounded-md w-full"
              placeholder="Your Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 font-century font-semibold">
            Login
          </button>
        </form>

        <button className="mt-4 w-full text-blue-600 hover:underline font-serif font-bold" onClick={onClose}>
          Close
        </button>

        {message && <p className="mt-4 text-red-500">{message}</p>}
      </div>
    </div>
  );
}
