import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { register } from '../redux/userSlice'; // Import the login action

export default function Signup({ onClose }) {
  const dispatch = useDispatch(); // Initialize the Redux dispatch

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://zetspace-backend-1.onrender.com/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage('User registered successfully!');
        
        // Dispatch login action to store user data in Redux store
        dispatch(register(formData.name));

        // Close the modal automatically
        setTimeout(() => {
          onClose();
        }, 1000); // Optional delay before closing
      } else {
        const errorData = await response.json();
        setMessage(`Error: ${errorData.message}`);
      }
    } catch (error) {
      setMessage('Error: Unable to register');
    }
  };

  // Close modal if the user clicks outside the modal content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50" onClick={handleOverlayClick}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl relative" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 sm:mb-5 md:mb-6 lg:mb-7 xl:mb-8">
          Sign Up
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-xs text-start sm:text-sm md:text-base lg:text-lg text-gray-700 font-semibold"
            >
              Name
            </label>

            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full text-sm sm:text-base"
              placeholder="Your Name"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-xs text-start  sm:text-sm md:text-base lg:text-lg text-gray-700 font-semibold"
            >
              Email
            </label>

            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full text-sm sm:text-base"
              placeholder="Your Email"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-start  text-xs sm:text-sm md:text-base lg:text-lg text-gray-700 font-semibold"
            >
              Password
            </label>

            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              className="border border-gray-300 p-2 rounded-md w-full text-sm sm:text-base"
              placeholder="Your Password"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 text-sm sm:text-base md:text-lg lg:text-xl font-semibold"
          >
            Sign Up
          </button>
        </form>

        {message && <p className="mt-4">{message}</p>}

        <button
          className="mt-4 w-full text-blue-600 hover:underline text-sm sm:text-base md:text-lg lg:text-xl"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}
