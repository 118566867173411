import React from 'react';
import { useInView } from 'react-intersection-observer';
import Media from './Media';

export default function Page1() {
  const { ref: PageRef, inView: PageInView } = useInView();

  return (
    <div id='page1' ref={PageRef} className={`h-screen flex justify-center  items-center ${PageInView ? 'animate-fadeInLeft' : ''}`}>
        <p className="text-white md:m-0 m-4 font-century bg-gray-500 bg-opacity-50 text-base sm:text-lg md:text-xl lg:text-2xl p-4 sm:p-6 md:p-8 lg:p-10 rounded-xl tracking-wider sm:tracking-wider w-full max-w-2xl">
        At ZetSpace, our services are designed with precision and a customer-first mindset.We begin by thoroughly understanding your unique business challenges and objectives.
        </p>
        
    </div>
  );
}
