import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import Software from './Software';
import Web from './Web';
import Media from './Media';

export default function CarouselDevelopment() {
  return (
    <div id="Dev1">
       
    <Swiper
         modules={[Navigation, Scrollbar, A11y, EffectCube]}
         spaceBetween={50}
         slidesPerView={1}
         effect="cube"
         speed={1000} 
         navigation
         scrollbar={{ draggable: true }} className='flex justify-center items-center'
    >
        <SwiperSlide className=''>   
            <Software />
        </SwiperSlide>
        <SwiperSlide className=''>    
            <Web/>          
        </SwiperSlide>
    </Swiper>
    
    
    </div>
  )
}
