import Home from "./components/Home"
import './App.css';
import Development from './components/Development';
import Services from './components/Services'
import Software from './components/Software'
import Follow from './components/Follow'
import Page1 from './components/Page1'
import Designing from './components/Designing';
import Socialmedia from './components/Socialmedia';
import Web from './components/Web'
import Ui from './components/Ui';
import Graphic from './components/Graphic';
import Video from './components/Video'
import Social from './components/Social';
import Multimedia from './components/Multimedia';
import About from "./components/About";
import CarouselDesign from "./components/CarouselDesign";
import CarouselDevelopment from "./components/CarouselDvelopment";
import CarouselMedia from "./components/CarouselMedia";
import BGvideo from "./components/BGvideo";
import Media from "./components/Media";



function App() {
  return (
    <div className="App  ">
      <BGvideo/>
      <Media/>
      <Home/>
      <Page1/>
      <About/>
      <Services/>
      <Development/>
      {/* <Software/>
      <Web/> */}
      <CarouselDevelopment/>
      <Designing/>
      {/* <Ui/>
      <Graphic/>
      <Video/> */}
      <CarouselDesign/>
      <Socialmedia/>
      {/* <Social/>
      <Multimedia/> */}
      <CarouselMedia/>
      <Follow/>
      </div>
  );
}

export default App;
