import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export default function Media() {
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    if (scrollPosition / documentHeight > 0.9) { // Adjust the percentage as needed
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <div className={`fixed bottom-0 left-0 p-4 flex flex-col space-y-5 z-50 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
        <a href='https://www.instagram.com/zetspace.in/' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faInstagram} size="2x" className="text-white hover:text-gray-300 cursor-pointer" />
        </a>
        <a href='https://www.facebook.com/' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faFacebook} size="2x" className="text-white hover:text-gray-300 cursor-pointer" />
        </a>
        <a href='https://www.linkedin.com/company/zetspace.in/' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faLinkedin} size="2x" className="text-white hover:text-gray-300 cursor-pointer" />
        </a>
        <a href='mailto:zetspaceinfo@gmail.com'>
          <FontAwesomeIcon icon={faEnvelope} size="2x" className="text-white hover:text-gray-300 cursor-pointer" />
        </a>
        <a href='https://www.zetspace.in/support' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faQuestionCircle} size="2x" className="text-white hover:text-gray-300 cursor-pointer" />
        </a>
      </div>
    
  );
}
