import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'; // Import from react-redux
import SideIMg from '../assets/images/imagine more innovate beyond.png';
import Welcome from './Welcome';
import zetspace from '../assets/images/zetspace.png';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Signup from './Signup';
import Login from './Login';
import { logout } from '../redux/userSlice'; // Import your logout action

export default function Home() {
  const [isVisible, setIsVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user); // Access the user from the Redux store

  const handleCloseSignup = () => {
    setShowSignup(false);
    document.body.style.overflow = "auto";
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 27) {
        handleCloseSignup();
        handleCloseLogin();
      }
    };

    if (showSignup || showLogin) {
      window.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [showSignup, showLogin]);

  const handleSignUpClick = () => {
    setShowSignup(true);
    document.body.style.overflow = "hidden";
  };

  const handleLoginClick = () => {
    setShowLogin(true);
    document.body.style.overflow = "hidden";
  };

  const handleLogoutClick = () => {
    dispatch(logout()); // Dispatch the logout action
  };

  const handleClose = () => {
    setIsVisible(false);
    document.body.style.overflow = 'auto';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 27) {
        handleClose();
        if (menuOpen) toggleMenu();
      }
    };

    if (isVisible || menuOpen) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isVisible, menuOpen]);

  return (
    <div id="Homee" className="flex flex-col min-h-screen">
      <div className="fixed top-0 left-0 w-full bg-black/10 z-50 hidden md:block">
        <div className="flex items-center justify-between w-full">
          <img src={zetspace} className="w-[10%]" alt="logo zetspace" />
          <div className="flex justify-between items-center font-bold text-white w-[80%] mx-[5%]">
            <p><Link to="Home" spy={true} smooth={true} offset={-450} duration={1000} className="hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4">Home</Link></p>
            <p><Link to="About" spy={true} smooth={true} offset={5} duration={1000} className="hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4">About</Link></p>
            <p><Link to="Services" spy={true} smooth={true} offset={5} duration={1000} className="hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4">Services</Link></p>
            <p><Link to="Contact" spy={true} smooth={true} offset={50} duration={1000} className="hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4">Contact</Link></p>

            {/* Conditionally render based on user state */}
            {user.isLoggedIn ? (
              <>
                <p className="text-white text-base">Hello, {user.name}</p>
                <p className=" hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4" onClick={handleLogoutClick}>Logout</p>
              </>
            ) : (
              <>
                <p className="hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4" onClick={handleLoginClick}>Login</p>
                <p className="hover:text-blue-100 cursor-pointer text-white text-base hover:underline hover:underline-offset-4" onClick={handleSignUpClick}>SignUp</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='fixed top-0 left-0 w-full bg-black/10 z-50 md:hidden'>
        <div className='flex items-center justify-between w-full  md:hidden px-5'>
          <img src={zetspace} className="w-[20vh] h-[10vh]" alt="logo zetspace" />   
          <div className="md:hidden">
                <button onClick={toggleMenu} className="text-white">
                    <FontAwesomeIcon icon={faBars} style={{ width: '25px', height: '35px' }} />
                </button>
            </div>
            {menuOpen && (
            <div className="fixed inset-0 bg-gradient-to-b from-gray-700 to-gray-500/90 z-50 flex flex-col items-center justify-center">
                <button onClick={toggleMenu} className="absolute top-4 right-4 text-black">
                    <FontAwesomeIcon icon={faTimes} style={{ width: '25px', height: '35px' }} />
                </button>
                <img src={zetspace} className=" text-black font-extrabold font-serif w-3/4 -mt-44 "/>
                <ul className="flex flex-col gap-8 text-center ">
                <p><Link to="Home" onClick={toggleMenu} spy={true} smooth={true} offset={-450} duration={1000}   className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4">Home</Link></p>
               <p><Link to="About"onClick={toggleMenu} spy={true} smooth={true} offset={5} duration={1000}   className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4 ">About</Link></p>
               <p><Link to="Services" onClick={toggleMenu} spy={true} smooth={true} offset={5} duration={1000}   className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4 ">Services</Link></p>
               <p><Link to="Contact" onClick={toggleMenu} spy={true} smooth={true} offset={10} duration={1000}   className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4 ">Contact</Link></p>
            {/* Conditionally render based on user state */}
            {user.isLoggedIn ? (
              <>
                <p className="text-white text-base font-bold">Hello, {user.name}</p>
                <p className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4 
                " onClick={handleLogoutClick}>Logout</p>
              </>
            ) : (
              <>
                <p className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4 " onClick={handleLoginClick}>Login</p>
                <p className="hover:text-blue-500 cursor-pointer text-white font-custom text-lg tracking-widest  hover:underline hover:underline-offset-4 " onClick={handleSignUpClick}>SignUp</p>
              </>
            )}
                </ul>
            </div>
        )}
    </div>
  </div>

      {/* Main content area */}
      <div className='flex-1 flex relative pt-20'> {/* Adjust padding to avoid overlap */}
        {/* Social media icons fixed at the bottom left */}


        {/* Center content area */}
        <div className='flex-1 flex flex-col justify-center items-center text-center'>
          <Welcome/>
        </div>

        {/* Side image at the top right */}
        <div className=' top-0 right-0 p-4 hidden md:block'>
          <img src={SideIMg} className='w-[6vh] h-auto max-w-[300px]' alt="Side Image" />
        </div>
      </div>
        {/* Render Signup and Login modals conditionally */}
        {showSignup && <Signup onClose={handleCloseSignup} />}
      {showLogin && <Login onClose={handleCloseLogin} />}
    </div>
  );
};
